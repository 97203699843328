@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Poppins', sans-serif;
}

nav, footer {
  background-color: #1f2937 !important; /* bg-gray-800 */
  color: #ffffff !important; /* text-white */
  opacity: 0.6 !important;
  z-index: 50 !important;
}

/* Shooting Star Animation */
.shooting-star {
  position: absolute;
  width: 8px;
  height: 8px;
  background: #ffd700; /* Light gold color for better contrast */
  box-shadow: 0 0 15px 6px rgba(255, 215, 0, 0.8); /* Stronger gold glow */
  animation: shootingStar linear infinite;
}

.shooting-star::after {
  content: '';
  position: absolute;
  top: 0;
  left: -20px; /* Length of the trail */
  width: 20px;
  height: 8px;
  background: linear-gradient(to right, rgba(255, 215, 0, 0), rgba(255, 215, 0, 0.5)); /* Gradient trail */
}

.star-1 {
  top: 5%;
  left: 10%;
  animation-duration: 8s;
  animation-delay: 0s;
}

.star-2 {
  top: 15%;
  left: 25%;
  animation-duration: 9s;
  animation-delay: 1s;
}

.star-3 {
  top: 25%;
  left: 40%;
  animation-duration: 8.5s;
  animation-delay: 2s;
}

.star-4 {
  top: 35%;
  left: 55%;
  animation-duration: 10s;
  animation-delay: 3s;
}

.star-5 {
  top: 45%;
  left: 70%;
  animation-duration: 9.5s;
  animation-delay: 4s;
}

.star-6 {
  top: 55%;
  left: 15%;
  animation-duration: 8.8s;
  animation-delay: 5s;
}

.star-7 {
  top: 65%;
  left: 30%;
  animation-duration: 9.2s;
  animation-delay: 6s;
}

.star-8 {
  top: 75%;
  left: 45%;
  animation-duration: 8.5s;
  animation-delay: 7s;
}

@keyframes shootingStar {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  100% {
    transform: translate(500px, 500px);
    opacity: 0;
  }
}